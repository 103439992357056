import { Confirm } from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { downloadFile } from "../../apis/downloadApi";
import { useDeletePatient, useGetPatientBtn } from "../../apis/patientApi";
import patientAgreement from "../../assets/doc/Patient_obligation_information_consent_form.pdf";
import { userAuth } from "../../atom/userAtom";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import ProfileImage from "../../elements/ProfileImage";
import * as S3 from "../../styles/Common";
import { isPermissionStatus } from "../../utils/centerStatus";
import { IK } from "../../utils/i18n/keyStore";
import { getConditionItem } from "../../utils/studyStatus";
import * as S from "../layout/CardLayout/style/CardLayout.style";
import { PatientAgreementModal } from "./PatientAgreementModal";

const PatientInfo = ({ patient, agreementFile, setOpenModal, patientId }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  /** 환자 의무 정보 제공 동의서 */
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);

  /**제출한 의무 정보 제공 동의서 다운로드 */
  const downloadAgreement = (fileInfo) => {
    downloadFile(fileInfo.fileId, fileInfo.orgFileName, t);
  };

  /**권한 처리 */
  const user = useRecoilValue(userAuth);
  const patientAuth = isPermissionStatus(user?.roleNames, "patientList");

  /**환자 삭제 버튼 조회 */
  const { data: btnData } = useGetPatientBtn(patientId);
  const isBtn = btnData?.data;

  const deleteMutation = useDeletePatient();
  /**환자 삭제 */
  const onDelete = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        deleteMutation.mutate(patientId);
      },
      () => {}
    );
  };

  return (
    <>
      <S.CardProfileWrapper>
        <S.CardProfileBox>
          <S.CardProfileHead>
            <S.CardProfileHeadInfo>
              <S.CardProfileHeadInfoImg>
                <div>
                  <ProfileImage profile={patient?.profile} />
                </div>
              </S.CardProfileHeadInfoImg>
              <S.CardProfileHeadInfoTxt>
                <h2>
                  {`${patient.firstName} ${patient.lastName}`} {patient?.koreaName && `(${patient?.koreaName})`}
                </h2>
                <p>{patient.patientNumber}</p>
              </S.CardProfileHeadInfoTxt>
            </S.CardProfileHeadInfo>
            <S.CardProfileHeadBtnBottom>
              {patientAuth.patientDelete && isBtn && (
                <button type="button" onClick={onDelete}>
                  {t(IK.patient)} {t(IK.delete)}
                </button>
              )}
              {!!patient?.latestStudyId && (
                <button type="button" onClick={() => navigate(`/studyList/${patient?.latestStudyId}`)}>
                  {t(IK.study_to_go)}
                </button>
              )}
              {patientAuth.patientInfoFix && (
                <button type="button" onClick={() => setOpenModal(true)}>
                  {t(IK.modification_patient_information)}
                </button>
              )}
            </S.CardProfileHeadBtnBottom>
          </S.CardProfileHead>

          <S.CardProfileBodyInfo>
            <dl>
              <dt>{t(IK.birthday)}</dt>
              <dd>
                <span>{patient.birthday}</span>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.clinic_or_hospital)}</dt>
              <dd>
                <span>
                  [{patient.patientAddressDTO?.shipAddressName}] {patient.patientAddressDTO?.shipAddress} {patient.patientAddressDTO?.shipAddressDetail} {patient.patientAddressDTO?.shipPostalCode}
                </span>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.treatment_option)}</dt>
              <dd>
                <span>{t(IK[patient.packages])}</span>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.end_date)}</dt>
              <dd>
                <span>{patient.endDate || "TBD"}</span>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.clinical_condition)}</dt>
              <S3.ContentScroll as="dd" $small>
                <span>{getConditionItem(patient?.conditionItemList, t)}</span>
              </S3.ContentScroll>
            </dl>
            <dl>
              <dt>{t(IK.patient_memo)}</dt>
              <S3.ContentScroll as="dd" $small>
                <S3.TextDiv $textpreline>{patient.memo}</S3.TextDiv>
              </S3.ContentScroll>
            </dl>
            <dl>
              <S.ProfileEditBox>
                {t(IK.patient_consent_form)}
                <S.ProfileEditBtn to={patientAgreement} download target="_blank">
                  <i className="ri-download-2-fill"></i>
                </S.ProfileEditBtn>
              </S.ProfileEditBox>
              <dd>
                {patient.agreementYn === "Y" ? (
                  <S2.ButtonFlex>
                    <S2.StyledButton as="button" type="button" $secondary onClick={() => setAgreementModalOpen(true)}>
                      {t(IK.reregistration)}
                    </S2.StyledButton>
                    <S2.StyledButton $primary onClick={() => downloadAgreement(patient?.patientAgreement)}>
                      {t(IK.submitted)} {t(IK.download)}
                    </S2.StyledButton>
                  </S2.ButtonFlex>
                ) : (
                  <S2.ButtonFlex>
                    <S2.StyledButton as="button" type="button" $secondary onClick={() => setAgreementModalOpen(true)}>
                      {t(IK.upload)}
                    </S2.StyledButton>
                  </S2.ButtonFlex>
                )}
              </dd>
            </dl>
          </S.CardProfileBodyInfo>
        </S.CardProfileBox>
      </S.CardProfileWrapper>
      {/* 환자 의무 정보 제공 동의서 */}
      <PatientAgreementModal setAgreementModalOpen={setAgreementModalOpen} patientId={patientId} agreementModalOpen={agreementModalOpen} />
    </>
  );
};

export default PatientInfo;
