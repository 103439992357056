import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { IK } from "../../utils/i18n/keyStore";

const ClinicalFile = ({ title, setValue, name, value, isImg }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <FormControlsBox title={title}>
      <S2.FormControlsFlex>
        <S2.FormControls disabled value={value} />
        <S2.FormControls type="file" id={name} accept={isImg ? "image/*" : "video/*"} hidden onChange={(event) => setValue(name, event.target.files[0])} />
        <S2.FormControlsBtn as="label" htmlFor={name}>
          {t(IK.file_btn)}
        </S2.FormControlsBtn>
      </S2.FormControlsFlex>
    </FormControlsBox>
  );
};

export default ClinicalFile;
