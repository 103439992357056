import Notiflix, { Confirm } from "notiflix";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddBoard, useDeleteBoard, useEditBoard, useGetBoardInfo } from "../../apis/boardApi";
import { CardTail, CardTitle } from "../../components/layout/CardLayout/CardLayout";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { NotiMessage } from "../../elements/Message/MessageText";
import TextEditor from "../../elements/TextEditor";
import { IK } from "../../utils/i18n/keyStore";
import BoardPostSkeleton from "./BoardPostSkeleton";

const BoardPostBox = ({ boardTitle, boardSortCode, boardId, navi = "/", video, attachFile }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**기존 게시판 데이터 받아오기 */
  const { data, isLoading } = useGetBoardInfo(boardId);
  const editBoardInfo = data?.data;

  const { register, handleSubmit, setValue, watch } = useForm();
  const editData = watch();

  const addBoardMutation = useAddBoard();
  const editBoardMutation = useEditBoard();
  const deleteMutation = useDeleteBoard();

  /**첨부파일 등록 */
  const [selectedFiles, setSelectedFiles] = useState([]);

  /**게시판 첨부파일용 */
  useEffect(() => {
    if (!isLoading && editBoardInfo?.boardFiles && !video) {
      editBoardInfo?.boardFiles?.attach && setSelectedFiles([...editBoardInfo?.boardFiles?.attach]);
      setValue("thumb", editBoardInfo?.boardFiles?.thumb?.[0]);
    }
  }, [isLoading, editBoardInfo, video, setValue]);

  /**더 잘하기 첨부파일용 */
  useEffect(() => {
    if (!isLoading && editBoardInfo?.boardFiles && video) {
      const { thumb, attach } = editBoardInfo?.boardFiles;
      if (thumb && thumb.length > 0) {
        setValue("thumb", thumb[0]);
      }
      if (attach && attach.length > 0) {
        setValue("attach", attach[0]);
      }
    }
  }, [isLoading, editBoardInfo, video, setValue]);

  /**아이디 값 추가하여 첨부파일 등록 */
  const handleFileChange = (event) => {
    const files = event.target.files;
    const timeId = Math.floor(Date.now() / 1000); // 고유한 아이디값 부여
    const updatedFiles = Array.from(files).map((file, index) => ({
      boardFileId: file.boardFileId || `${index}_${timeId}`,
      file: file,
    }));
    setSelectedFiles((prev) => [...prev, ...updatedFiles]);
  };

  /**첨부파일 삭제 */
  const [deleteFileIds, setDeleteFileIds] = useState([]);
  const handleFileDelete = (fileId) => {
    if (typeof fileId === "number") {
      setDeleteFileIds((prev) => [...prev, fileId]);
    }
    setSelectedFiles(selectedFiles.filter((fileObj) => fileObj.boardFileId !== fileId));
  };

  /**비디오등록 (새비디오등록시 기존 비디오 파일 삭제)*/
  const handleVideoChange = (event) => {
    setValue("attach", event.target.files[0]);
    if (editBoardInfo?.boardFiles?.attach) {
      setDeleteFileIds((prev) => [...prev, editBoardInfo?.boardFiles?.attach[0].boardFileId]);
    }
  };

  /**데이터 전송 */
  const submitContent = (data) => {
    //제목 미등록
    if (data.title?.trim() === "") {
      return Notiflix.Report.info("", t(IK.board_info), t(IK.confirm), () => {});
    }

    // //비디오제출에서 썸네일 및 영상 미등록
    // if (video && !data.thumb) {
    //   return Notiflix.Report.info("", t(IK.board_required), t(IK.confirm), () => {});
    // }

    //썸네일 미등록
    if (!data.thumb) {
      return Notiflix.Report.info("", t(IK.board_thumb_required), t(IK.confirm), () => {});
    }

    const formData = new FormData();
    const submitData = { ...data, deleteBoardFileIds: deleteFileIds };
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((value) => {
        if (typeof value.boardFileId === "string") {
          formData.append("attach", value.file);
        }
      });
    }
    for (const [key, value] of Object.entries(submitData)) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        // 빈값일 경우 건너뛴다.
        continue;
      }

      if ((key === "attach" || key === "thumb") && value.boardFileId) {
        // "attach" 또는 "thumb" 가 이미 등록된 파일일 경우, 건너뛴다.
        continue;
      }

      formData.append(key, value);
    }

    if (boardId) {
      editBoardMutation.mutate(
        { boardId, formData },
        {
          onSuccess: () => {
            Notiflix.Report.success("", t(IK.update_success), t(IK.confirm), () => {});
            navigate(navi);
          },
        }
      );
    } else {
      formData.append("boardSortCode", boardSortCode);
      addBoardMutation.mutate(formData, {
        onSuccess: () => {
          Notiflix.Report.success("", t(IK.add_success), t(IK.confirm), () => {});
          navigate(navi);
        },
      });
    }
  };

  /**게시글 삭제 */
  const onDelete = () => {
    Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => {
      deleteMutation.mutate(boardId, {
        onSuccess: () => {
          Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => navigate(navi));
        },
      });
    });
  };

  /**content 저장 */
  useEffect(() => {
    if (editBoardInfo) {
      setValue("content", editBoardInfo.content);
    }
  }, [editBoardInfo, setValue]);

  return (
    <>
      <S.CardRow>
        <S.CardCol>
          <S.CardInner>
            <CardTitle title={boardTitle} txt />
            {isLoading ? (
              <BoardPostSkeleton />
            ) : (
              <form onSubmit={handleSubmit(submitContent)}>
                <S2.FormControlsContainer>
                  <FormControlsBox required title={t(IK.board_title)}>
                    <S2.FormControls {...register("title")} defaultValue={editBoardInfo?.title || ""} />
                  </FormControlsBox>
                  <FormControlsBox required title={t(IK.board_content)}>
                    <TextEditor setData={(content) => setValue("content", content)} content={editBoardInfo?.content} />
                  </FormControlsBox>
                  <FormControlsBox required title={t(IK.board_thumb)}>
                    <S2.FormControlsFlex>
                      <S2.FormControls disabled value={editData?.thumb?.orgFileName || editData?.thumb?.name || ""} />
                      <S2.FormControls type="file" id="thumb" name="thumb" accept="image/*" onChange={(event) => setValue("thumb", event.target?.files[0])} hidden />
                      <S2.FormControlsBtn as="label" htmlFor="thumb">
                        {t(IK.file_btn)}
                      </S2.FormControlsBtn>
                    </S2.FormControlsFlex>
                    <NotiMessage text={`${t(IK.recommended_size)} 288 x 179`} />
                  </FormControlsBox>
                  {video && (
                    <FormControlsBox title={t(IK.video_limit)}>
                      <S2.FormControlsFlex>
                        <S2.FormControls disabled value={editData?.attach?.orgFileName || editData.attach?.name || ""} />
                        <S2.FormControls type="file" id="attach" name="attach" accept="video/*" onChange={handleVideoChange} hidden />
                        <S2.FormControlsBtn as="label" htmlFor="attach">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                    </FormControlsBox>
                  )}
                  {attachFile && (
                    <>
                      <FormControlsBox title={t(IK.attached_file)}>
                        <S2.FormControlsFlex>
                          <input id="bordFile" type="file" hidden multiple onChange={handleFileChange} />
                          <S2.FormControlsBtn as="label" htmlFor="bordFile">
                            {t(IK.file_btn)}
                          </S2.FormControlsBtn>
                        </S2.FormControlsFlex>
                      </FormControlsBox>
                      {selectedFiles.length > 0 && (
                        <FormControlsBox title={`${t(IK.attached_file)} ${t(IK.list)}`}>
                          <S2.FormFileList>
                            {selectedFiles.map((item) => (
                              <li key={item.boardFileId}>
                                {item.file?.name || item.orgFileName}
                                <button onClick={() => handleFileDelete(item.boardFileId)}>
                                  <i className="ri-close-line"></i>
                                </button>
                              </li>
                            ))}
                          </S2.FormFileList>
                        </FormControlsBox>
                      )}
                    </>
                  )}
                </S2.FormControlsContainer>
                <CardTail line>
                  <S3.ButtonLtBox>
                    {boardId ? (
                      <>
                        <S3.StyledButton as="button" type="button" onClick={() => navigate(`${navi}${location.state?.herf || `?page=1&sort=${boardSortCode}`}`)}>
                          {t(IK.list)}
                        </S3.StyledButton>
                        <S3.StyledButton as="button" $secondary type="button" onClick={onDelete}>
                          {t(IK.delete)}
                        </S3.StyledButton>
                        <S3.StyledButton as="button" $primary type="submit">
                          {t(IK.modify)}
                        </S3.StyledButton>
                      </>
                    ) : (
                      <>
                        <S3.StyledButton as="button" type="button" onClick={() => navigate(navi)}>
                          {t(IK.cancel)}
                        </S3.StyledButton>
                        <S3.StyledButton as="button" type="submit" $primary>
                          {t(IK.registration)}
                        </S3.StyledButton>
                      </>
                    )}
                  </S3.ButtonLtBox>
                </CardTail>
              </form>
            )}
          </S.CardInner>
        </S.CardCol>
      </S.CardRow>
    </>
  );
};

export default BoardPostBox;
