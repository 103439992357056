import axios from "axios";

/** 아이디 중복 확인  */
export const checkLoginId = async (id) => {
  const response = await axios.post(`/api/account/check-id`, id);
  return response;
};

/** 이메일 중복 확인  */
export const checkEmail = async (email) => {
  const response = await axios.post(`/api/account/check-email`, email);
  return response;
};

/** referesh 토큰 쿠키 지우기
 *  httpyOnly 쿠키는 서버에서만 제거할 수 있음.
 */
export const deleteRefreshToken = async () => {
  const response = await axios.delete(`/api/auth/refresh-token`);
  return response;
};

/**로그인 화면에서 referesh 토큰 유효성 체크  */
export const checkToken = async () => {
  const response = await axios.get(`/api/auth/is-login`);
  return response;
};

/**유저 권한 확인(로그인용) */
export const getUserPermissionCheck = async () => {
  const response = await axios.get(`/api/admin/user-info`);
  return response;
};
