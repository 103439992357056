import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/**세라뷰 파일 조회 */
export const useGetSeraview = () => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["GetSeraview"],
    async () => {
      const { data } = await axios.get("/api/board/support/seraview");
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**세라뷰 파일 등록 */
export const usePostSeraview = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (data) => {
      Notiflix.Loading.standard("");
      await axios.post(`/api/board/support/seraview`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetSeraview");
        Notiflix.Loading.remove();
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Loading.remove();
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**배송키 조회 */
export const useGetDeliveryKey = () => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["GetDeliveryKey"],
    async () => {
      const { data } = await axios.get("/api/board/support/delivery");
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

export const usePostDeliveryKey = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (key) => {
      await axios.post(`/api/board/support/delivery?key=${key}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("GetDeliveryKey");
        Notiflix.Report.success(t(IK.file_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.file_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};
