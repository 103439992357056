import { useTranslation } from "react-i18next";
import * as S from "../../components/board/style/BoardList.style";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import ProfileImage from "../../elements/ProfileImage";
import * as S3 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { seraphinOption, studyStatusListLabel } from "../../utils/studyStatus";

export const PatientsTalbleTr = ({ patients }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      {patients.map((patient) => (
        <tr key={patient?.patientId}>
          <td>
            <S.BoardTableProfile to={`/patientList/${patient?.patientId}`} state={{ herf: window.location.search }}>
              <S.BoardTableThumbnail>
                <ProfileImage profile={patient?.patientProfile} />
              </S.BoardTableThumbnail>
              <S.BoardTableName>
                <strong>
                  {`${patient.patientFirstName} ${patient.patientLastName}`} {patient?.patientKoreaName && `(${patient.patientKoreaName})`}
                </strong>
                <p>{patient.patientNumber}</p>
              </S.BoardTableName>
            </S.BoardTableProfile>
          </td>
          <td>{patient.birthday}</td>
          <td>{t(IK[patient.gender])}</td>
          <td>
            {patient.doctorName} {patient?.doctorKoreaName && `(${patient.doctorKoreaName})`}
          </td>
          <td>{patient.clinicName}</td>
          <td>{seraphinOption[patient.packages]}</td>
          <td>
            {(patient.detail === "01" || patient.detail > 10) && (
              <S.BoardTableState>
                <S.BoardTableStateList>
                  <S.BoardTableStateItem $progress={patient.detail <= "14"} $complete={patient.detail >= "14"}>
                    <span></span>
                  </S.BoardTableStateItem>
                  {!(patient.packages === "RP" || patient.replacementYn === "Y" || patient?.additionalProcess === "none") && (
                    <S.BoardTableStateItem $progress={patient.detail <= "17" && patient.detail > "14"} $complete={patient.detail >= "17"}>
                      <span></span>
                    </S.BoardTableStateItem>
                  )}
                  <S.BoardTableStateItem $last $progress={patient.detail >= "18" && patient.detail !== "21"} $complete={patient.detail === "20" || patient.detail === "22"}>
                    <span></span>
                  </S.BoardTableStateItem>
                </S.BoardTableStateList>
                <h4>
                  {patient?.additionalAmountYn === "Y" && `${t(IK.pay_need)}) `}
                  {patient.additionalYn === "Y" && `${t(IK.additional_device)} `}
                  {patient.replacementYn === "Y" && `${t(IK.alternative_correction_devices)} `}
                  {studyStatusListLabel(patient, t)}
                </h4>
                <p>
                  {patient?.lastCommentByDoctor === "Y" && <S3.TextDiv $textColorRed600>{t(IK.comment_writed_doctor)}</S3.TextDiv>}
                  {patient?.lastCommentByDoctor === "N" && <S3.TextDiv>{t(IK.comment_writed_center)}</S3.TextDiv>}
                </p>
              </S.BoardTableState>
            )}
          </td>
          <td>{patient.patientCreatedDate}</td>
          <td>{patient?.patientAgreement && <S2.StyledSmallButton onClick={() => window.open(patient?.patientAgreement, "_blank")}>{t(IK.agreement)}</S2.StyledSmallButton>}</td>
        </tr>
      ))}
    </>
  );
};
