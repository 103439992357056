import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../elements/Form/style/Form.style";
import * as S2 from "../../elements/Table/style/Table.style";
import { IK } from "../../utils/i18n/keyStore";

const ClinicalSearchFilter = ({ setSearchCondition, searchCondition }) => {
  const { t } = useTranslation(["translation"]);

  const { register, handleSubmit, reset } = useForm();

  const onSubmitSearch = (data) => {
    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      dentistName: "",
      patientName: "",
      submissionStatus: "",
    });

    setSearchCondition({ page: 1 });
  };

  return (
    <S.CardInner>
      <form onSubmit={handleSubmit(onSubmitSearch)}>
        <S2.TableType2>
          <colgroup>
            <col width="200px"></col>
            <col width="*"></col>
            <col width="200px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{t(IK.clinic_name)}</th>
              <td>
                <S3.FormControls placeholder={t(IK.search_clinic_input)} defaultValue={searchCondition?.dentistName} {...register("dentistName")} />
              </td>
              <th scope="row">{t(IK.patient_name)}</th>
              <td>
                <S3.FormControls placeholder={t(IK.search_patient_input)} defaultValue={searchCondition?.patientName} {...register("patientName")} />
              </td>
            </tr>
            <tr>
              <th scope="row">{t(IK.clinical_is_submit)}</th>
              <td colSpan={3}>
                <S3.FormControls {...register("submissionStatus")} defaultValue={searchCondition?.submissionStatus} as="select" $select>
                  <option value="">{t(IK.clinical_is_submit)}</option>
                  <option value="REGISTERED">{t(IK.clinical_submit)}</option>
                  <option value="NOT_REGISTERED">{t(IK.clinical_not_submit)}</option>
                </S3.FormControls>
              </td>
            </tr>
          </tbody>
        </S2.TableType2>
        <S4.ButtonCtBox>
          <S4.StyledButton type="button" onClick={() => onReset()} as="button">
            <i className="ri-refresh-line"></i> {t(IK.reset)}
          </S4.StyledButton>
          <S4.StyledButton $primary type="submit" as="button">
            <i className="ri-search-2-line"></i> {t(IK.search)}
          </S4.StyledButton>
        </S4.ButtonCtBox>
      </form>
    </S.CardInner>
  );
};

export default ClinicalSearchFilter;
