import DragImage from "../../elements/Drop/DragImage";
import * as S6 from "../../elements/Drop/style/Drop.style";
import { ErrorMessage } from "../../elements/Message/MessageText";
import * as S5 from "../../styles/Common";

const ClinicalPreview = ({ label, imageList, setValue, setDeleteClinicalFileIds, deleteClinicalFileIds, galleryData, isRadiation, required, errors, trigger }) => {
  return (
    <S5.ContentBox>
      <S5.ListGroup>
        <dt>{label}</dt>
        <dd>
          <S6.DropListWrap $radiation={isRadiation}>
            {imageList?.map((img, index) => (
              <S6.DropList className={index !== 1 && required ? `required_img ${img.style}` : `${img.style}`} key={img.style}>
                <DragImage
                  isRadiation={isRadiation}
                  setValue={setValue}
                  name={img.name}
                  deleteFileIds={deleteClinicalFileIds}
                  setDeleteFileIds={setDeleteClinicalFileIds}
                  files={galleryData ? galleryData[img.name] : null}
                  trigger={trigger}
                />
                <ErrorMessage errors={errors} name={img.name} />
              </S6.DropList>
            ))}
          </S6.DropListWrap>
        </dd>
      </S5.ListGroup>
    </S5.ContentBox>
  );
};

export default ClinicalPreview;
