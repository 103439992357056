import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetPopupList } from "../apis/popupApi";
import BoardTotal from "../components/board/BoardTotal";
import * as S5 from "../components/board/style/BoardList.style";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import * as S3 from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import * as S2 from "../elements/Table/style/Table.style";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { pageListNumber } from "../utils/pageListNumber";
import { changeParam, urlParamToObject } from "../utils/searchParams";

const PopUpList = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const { register, handleSubmit, reset } = useForm();
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data, isLoading } = useGetPopupList(changeParam(searchCondition));
  const popups = data?.content;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition({ ...data, page: 1 });
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      type: "",
      display: "",
    });
    setSearchCondition({ page: 1 });
  };

  /**정렬 */
  const handleSorting = () => {
    if (!searchCondition?.sort) {
      setSearchCondition((prev) => ({
        ...prev,
        sort: "order,asc",
        page: 1,
      }));
    } else if (searchCondition?.sort === "order,asc") {
      setSearchCondition((prev) => ({
        ...prev,
        sort: "order,desc",
        page: 1,
      }));
    } else if (searchCondition?.sort === "order,desc") {
      setSearchCondition((prev) => {
        const { sort, ...rest } = prev;
        return { ...rest, page: 1 };
      });
    }
  };

  const tableCol = ["70px", "220px", "*", "220px", "220px", "220px", "180px"];
  const tableHead = [
    "No.",
    t(IK.pop_type),
    t(IK.pop_name),
    t(IK.pop_used),
    <S5.BoardTableSortBox type="button" onClick={handleSorting} $Primary={!searchCondition?.sort}>
      {/* BoardTableSortBox 클릭 시 "Primary" props 생성 */}
      {t(IK.pop_order)}
      {/* 오름차순일 경우 "SortAsc" props 생성 / 내림차순일 경우 "SortDesc" props 생성 */}
      <S5.BoardTableSortBoxSpan $SortAsc={searchCondition?.sort === "order,asc"} $SortDesc={searchCondition?.sort === "order,desc"}>
        <i className="ri-arrow-up-s-fill" />
        <i className="ri-arrow-down-s-fill" />
      </S5.BoardTableSortBoxSpan>
    </S5.BoardTableSortBox>,
    t(IK.pop_date),
    t(IK.register_date),
  ];

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <S2.TableType2>
              <colgroup>
                <col width="200px"></col>
                <col width="*"></col>
                <col width="200px"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>{t(IK.pop_type)}</th>
                  <td>
                    <S3.FormControls $select as="select" {...register("type")} defaultValue={searchCondition?.boardSelect}>
                      <option value="">{t(IK.select)}</option>
                      <option value="IMAGE">{t(IK.pop_type_image)}</option>
                      <option value="YOUTUBE">{t(IK.pop_type_video)}</option>
                    </S3.FormControls>
                  </td>
                  <th>{t(IK.pop_used)}</th>
                  <td>
                    <S3.FormControls $select as="select" {...register("display")} defaultValue={searchCondition?.boardSelect}>
                      <option value="">{t(IK.select)}</option>
                      <option value={true}>{t(IK.pop_used_use)}</option>
                      <option value={false}>{t(IK.pop_used_not_use)}</option>
                    </S3.FormControls>
                  </td>
                </tr>
              </tbody>
            </S2.TableType2>
            <S4.ButtonCtBox>
              <S4.StyledButton type="button" onClick={() => onReset()} as="button">
                <i className="ri-refresh-line"></i> {t(IK.reset)}
              </S4.StyledButton>
              <S4.StyledButton $primary type="submit" as="button">
                <i className="ri-search-2-line"></i> {t(IK.search)}
              </S4.StyledButton>
            </S4.ButtonCtBox>
          </form>
        </S.CardInner>
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead line>
              <BoardTotal total={data?.totalElements} />
              <S4.StyledButton $primary to="/support/popup/post">
                {t(IK.registration)}
              </S4.StyledButton>
            </CardHead>
            <DataTable
              dataLength={data?.totalElements}
              tableCol={tableCol}
              tableHead={tableHead}
              tableTr={
                <>
                  {popups?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{pageListNumber(data?.totalElements, searchCondition?.page, index)}</td>
                      <td>{item?.type === "IMAGE" ? t(IK.pop_type_image) : t(IK.pop_type_video)}</td>
                      <td>
                        <S5.BoardTableLink to={`/support/popup/post/${item.id}`} state={{ herf: window.location.search }}>
                          {item?.name}
                        </S5.BoardTableLink>
                      </td>
                      <td>{item?.display ? t(IK.pop_used_use) : t(IK.pop_used_not_use)}</td>
                      <td>{item?.order}</td>
                      <td>{!item?.startDate ? t(IK.no_limit) : `${dayjs(item?.startDate).format("YYYY-MM-DD-HH:mm")} ~ ${dayjs(item?.endDate).format("YYYY-MM-DD-HH:mm")}`}</td>
                      <td>{dayjs(item?.createdDate).format("YYYY-MM-DD")}</td>
                    </tr>
                  ))}
                </>
              }
            />
            <CardTail line>
              <Paging totalCount={data?.totalElements} currentPage={searchCondition.page} size={data?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default PopUpList;
