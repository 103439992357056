/**생년월일을 YYYY-MM-DD 형식의 문자열로 변환하는 함수 */
export function formatDate(inputDate) {
  if (inputDate.includes("-")) return inputDate;

  const year = inputDate.slice(0, 4);
  const month = inputDate.slice(-4, -2);
  const day = inputDate.slice(-2);

  try {
    // YYYY-MM-DD 형식의 문자열을 Date 객체로 변환
    const dateObj = new Date(`${year}-${month}-${day}`);
    // Date 객체를 YYYY-MM-DD 형식의 문자열로 변환
    const formattedDate = dateObj.toISOString().slice(0, 10);

    return formattedDate;
  } catch (e) {
    return null;
  }
}
