import dayjs from "dayjs";

/**객체를 param으로 변경 */
export const changeParam = (searchCondition) => {
  const { page, startDate, sort, endDate, startYear, endYear, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}:${value}`);

  if (startDate && endDate) {
    q.push(`createdDate:${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}`);
  }

  if (startYear && endYear) {
    q.push(`birthday:${dayjs(startYear).format("YYYY")}_${dayjs(endYear).format("YYYY")}`);
  }

  const param = {
    q: q.join(","),
    page: page - 1,
    size: 10,
  };

  if (sort) {
    // sort가 있을 경우 sort를 추가
    param.sort = sort;
  }

  return param;
};

/**param을 객체로 처리 */
export function urlParamToObject() {
  return Object.fromEntries(new URLSearchParams(window.location.search));
}

/**빌링 검색어 객체를 param으로 처리 */
export const billChangeParam = (searchCondition) => {
  const { page, endMonth, startMonth, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (startMonth && endMonth) {
    q.push(`billingMonth:${dayjs(startMonth).format("YYYY-MM-DD")}_${dayjs(endMonth).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
  };
};

/**보드게시판 객체를 param으로 변경 */
export const changeBoradParam = (searchCondition) => {
  const { page, sort, boardSelect, boardText, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (!!boardText) {
    q.push(`${boardSelect}:${boardText}`);
  }

  return {
    q: q.join(","),
    sort: sort,
    page: page - 1,
    size: 10,
  };
};

/**이벤트 게시판 검색조건 param */
export const changeEventParam = (searchCondition) => {
  const { page, searchStart, searchEnd, searchSelect, searchText, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (!!searchText) {
    q.push(`${searchSelect}:${searchText}`);
  }

  if (searchStart && searchEnd) {
    q.push(`startDate:${dayjs(searchStart).format("YYYY-MM-DD")}_${dayjs(searchEnd).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
  };
};
