import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useGetSeraview, usePostSeraview } from "../apis/supportApi";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import * as S3 from "../elements/Form/style/Form.style";
import { TextErrorMessage } from "../elements/Message/MessageText";
import * as S2 from "../elements/Table/style/Table.style";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";

const SeraviewFile = () => {
  const { t } = useTranslation(["translation"]);
  const { data, isLoading } = useGetSeraview();
  const seraview = data?.data;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const fileInfo = watch("setupFile");

  const seraviewFileMutation = usePostSeraview();

  const uploadFile = (data) => {
    const formData = new FormData();
    formData.append("version", data.version);
    formData.append("setupFile", data.setupFile[0]);
    seraviewFileMutation.mutate(formData, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <Skeleton height={150} />
          ) : (
            <form onSubmit={handleSubmit(uploadFile)} onKeyDown={enterKeyDown}>
              <S2.TableType2>
                <colgroup>
                  <col width="200px"></col>
                  <col width="*"></col>
                  <col width="200px"></col>
                  <col width="*"></col>
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">version</th>
                    <td>
                      <S3.FormControls
                        type="text"
                        defaultValue={seraview?.version}
                        {...register("version", {
                          required: true,
                        })}
                        disabled={!fileInfo?.[0]?.name}
                      />
                      <TextErrorMessage
                        errors={errors.version}
                        message={t(IK.seraview_file_version_error)}
                      />
                    </td>
                    <th scope="row">{t(IK.seraview_file)}</th>
                    <td>
                      <S3.FormControlsFlex>
                        <S3.FormControls
                          $full
                          value={fileInfo?.[0]?.name || seraview?.orgFileName}
                          disabled
                        />
                        <S4.StyledButton $primary htmlFor="serafile" as="label">
                          {t(IK.modify)}
                        </S4.StyledButton>
                        <S3.FormControls
                          id="serafile"
                          type="file"
                          {...register("setupFile")}
                          hidden
                        />
                      </S3.FormControlsFlex>
                    </td>
                  </tr>
                </tbody>
              </S2.TableType2>
              <S4.ButtonCtBox>
                <S4.StyledButton
                  $primary
                  as="button"
                  disabled={!fileInfo?.[0]?.name}
                >
                  {t(IK.registration)}
                </S4.StyledButton>
              </S4.ButtonCtBox>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default SeraviewFile;
