import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAddEvent, useDeleteEvent, useEditEvent, useGetEventInfo } from "../../apis/eventApi";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { TimePicker } from "../../elements/DatePicker";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { ErrorMessage, NotiMessage } from "../../elements/Message/MessageText";
import * as S4 from "../../elements/SelectionControls/style/SelectionControls.style";
import TextEditor from "../../elements/TextEditor";
import { IK } from "../../utils/i18n/keyStore";
import BoardPostSkeleton from "../board/BoardPostSkeleton";
import { CardTail, CardTitle } from "../layout/CardLayout/CardLayout";

const EventBoardPost = () => {
  const { eventBoardId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["translation"]);

  /**유효성 검사 */
  const formSchema = Yup.object({
    title: Yup.string().required(t(IK.event_title_required)),
    startDate: Yup.string().required(t(IK.event_start_date_required)),
    endDate: Yup.string().required(t(IK.event_end_date_required)),
    bannerShowYn: Yup.string().required(t(IK.event_vip_show_required)),
    bannerSortOrder: Yup.string().when("bannerShowYn", {
      is: (val) => val === "Y",
      then: (val) => Yup.string().matches(/^\d*$/, t(IK.only_number)).required(t(IK.event_banner_order_required)),
    }),
    banner: Yup.mixed().when("bannerShowYn", {
      is: (val) => val === "Y",
      then: (val) => Yup.mixed().required(t(IK.event_banner_image_required)),
    }),
    thumb: Yup.mixed().required(t(IK.board_thumb_required)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const boardWatch = watch();

  /**이벤트 게시판 상세조회 */
  const { data, isLoading } = useGetEventInfo(eventBoardId);
  const editBoardInfo = data?.data;

  useEffect(() => {
    if (!isLoading && editBoardInfo) {
      const { startDate, endDate, bannerFile, eventBoardFile } = editBoardInfo;
      setValue("startDate", dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"));
      setValue("endDate", dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"));
      setValue("bannerShowYn", bannerFile[0]?.showYn || "N");
      setValue("banner", bannerFile[0]);
      setValue("thumb", eventBoardFile[0]);
    }
  }, [isLoading, editBoardInfo, setValue]);

  /**이벤트 시작 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("startDate", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setValue("startDate", "");
    }
  };

  /**이벤트 종료 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("endDate", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setValue("endDate", "");
    }
  };

  const addEventMutation = useAddEvent();
  const editEventMutation = useEditEvent();
  /**이벤트 게시물 등록 */
  const submitContent = (data) => {
    if (data.startDate > data.endDate) {
      //종료일이 시작일 보다 빠른 경우 경고
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    const formData = new FormData();

    const bannerFileId = editBoardInfo?.bannerFile[0]?.bannerFileId;
    const eventBoardFileId = editBoardInfo?.eventBoardFile[0]?.eventBoardFileId;

    //배너 반영 아니오 선택시 데이터 삭제
    if (data.bannerShowYn === "N") {
      delete data.bannerSortOrder;
      delete data.banner;
      bannerFileId && formData.append("deleteBannerId", bannerFileId);
    }

    for (const [key, value] of Object.entries(data)) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if ((key === "banner" || key === "thumb") && value.fileId) {
        continue;
      }

      formData.append(key, value);
    }

    if (eventBoardId) {
      //새롭게 등록(name이 있음) && 기존 파일 아이디를 가진 경우 삭제
      if (boardWatch?.banner?.name && !!bannerFileId) {
        formData.append("deleteBannerId", bannerFileId);
      }
      if (boardWatch?.thumb?.name && !!eventBoardFileId) {
        formData.append("deleteBoardFileId", eventBoardFileId);
      }
      editEventMutation.mutate({ eventBoardId, data: formData });
    } else {
      addEventMutation.mutate(formData);
    }
  };

  const deleteEventMutation = useDeleteEvent();
  /**게시물 삭제 */
  const handleDelete = () => {
    Notiflix.Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        deleteEventMutation.mutate(eventBoardId);
      },
      () => {}
    );
  };

  /**content 저장 */
  useEffect(() => {
    if (editBoardInfo) {
      setValue("content", editBoardInfo.content);
    }
  }, [editBoardInfo, setValue]);

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.event)} txt />
          {isLoading ? (
            <BoardPostSkeleton />
          ) : (
            <form onSubmit={handleSubmit(submitContent)}>
              <S2.FormControlsContainer>
                <FormControlsBox required title={t(IK.board_title)}>
                  <S2.FormControls {...register("title")} defaultValue={editBoardInfo?.title || ""} />
                  <ErrorMessage errors={errors} name="title" />
                </FormControlsBox>
                <FormControlsBox title={t(IK.board_content)}>
                  <TextEditor setData={(content) => setValue("content", content)} content={editBoardInfo?.content} />
                </FormControlsBox>
                <FormControlsBox required title={t(IK.event_date)}>
                  <S2.FormControlsFlex>
                    <div>
                      <TimePicker
                        handleDataChange={handleStartDate}
                        placeholderText={t(IK.create_date)}
                        selected={(boardWatch.startDate && new Date(boardWatch.startDate)) || (editBoardInfo?.startDate && new Date(editBoardInfo.startDate))}
                        formTimeControls
                      />
                      {!boardWatch.startDate && <ErrorMessage errors={errors} name="startDate" />}
                    </div>

                    <div>
                      <TimePicker
                        handleDataChange={handleEndDate}
                        placeholderText={t(IK.event_end_date)}
                        selected={(boardWatch.endDate && new Date(boardWatch.endDate)) || (editBoardInfo?.endDate && new Date(editBoardInfo.endDate))}
                        formTimeControls
                      />
                      {!boardWatch.endDate && <ErrorMessage errors={errors} name="endDate" />}
                    </div>
                  </S2.FormControlsFlex>
                </FormControlsBox>

                <FormControlsBox required title={t(IK.event_vip_show)}>
                  <S4.SelectionItemList>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput $radio type="radio" id="showYn_Y" {...register("bannerShowYn")} value="Y" />
                        <S4.SelectionItemSpan htmlFor="showYn_Y">{t(IK.yes)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput $radio type="radio" id="showYn_N" {...register("bannerShowYn")} value="N" />
                        <S4.SelectionItemSpan htmlFor="showYn_N">{t(IK.no)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="bannerShowYn" />
                </FormControlsBox>

                {boardWatch.bannerShowYn === "Y" && (
                  <>
                    <FormControlsBox title={t(IK.event_vip_show_order)} required>
                      <S2.FormControls $small {...register("bannerSortOrder")} defaultValue={editBoardInfo?.bannerFile[0]?.sortOrder || ""} placeholder="ex) 1" />
                      <ErrorMessage errors={errors} name="bannerSortOrder" />
                    </FormControlsBox>

                    <FormControlsBox title={t(IK.event_banner)} required>
                      <S2.FormControlsFlex>
                        <S2.FormControls disabled value={boardWatch.banner?.name || editBoardInfo?.bannerFile[0]?.orgFileName || ""} />
                        <S2.FormControls type="file" id="banner" name="banner" accept="image/*" onChange={(event) => setValue("banner", event.target.files[0])} hidden />
                        <S2.FormControlsBtn as="label" htmlFor="banner">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                      {!boardWatch.banner && <ErrorMessage errors={errors} name="banner" />}
                      <NotiMessage text={`${t(IK.recommended_size)} 196 x 100`} />
                    </FormControlsBox>
                  </>
                )}

                <FormControlsBox required title={t(IK.board_thumb)}>
                  <S2.FormControlsFlex>
                    <S2.FormControls disabled value={boardWatch.thumb?.name || editBoardInfo?.eventBoardFile[0]?.orgFileName || ""} />
                    <S2.FormControls type="file" id="thumb" name="thumb" accept="image/*" onChange={(event) => setValue("thumb", event.target.files[0])} hidden />
                    <S2.FormControlsBtn as="label" htmlFor="thumb">
                      {t(IK.file_btn)}
                    </S2.FormControlsBtn>
                  </S2.FormControlsFlex>
                  {!boardWatch.thumb && <ErrorMessage errors={errors} name="thumb" />}
                  <NotiMessage text={`${t(IK.recommended_size)} 288 x 179`} />
                </FormControlsBox>
              </S2.FormControlsContainer>

              <CardTail line>
                <S3.ButtonLtBox>
                  {eventBoardId ? (
                    <>
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/support/event${location.state?.herf || `?page=1`}`)}>
                        {t(IK.list)}
                      </S3.StyledButton>
                      <S3.StyledButton as="button" $secondary type="button" onClick={handleDelete}>
                        {t(IK.delete)}
                      </S3.StyledButton>
                      <S3.StyledButton as="button" $primary type="submit">
                        {t(IK.modify)}
                      </S3.StyledButton>
                    </>
                  ) : (
                    <>
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/support/event${location.state?.herf || `?page=1`}`)}>
                        {t(IK.cancel)}
                      </S3.StyledButton>
                      <S3.StyledButton as="button" type="submit" $primary>
                        {t(IK.registration)}
                      </S3.StyledButton>
                    </>
                  )}
                </S3.ButtonLtBox>
              </CardTail>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default EventBoardPost;
