import { yupResolver } from "@hookform/resolvers/yup";
import { Confirm } from "notiflix";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useDeleteCenterInfo, useEditCenterInfo, useGetCenterInfo } from "../apis/centerApi";
import { userAuth } from "../atom/userAtom";
import CenterSkeleton from "../components/center/CenterSkeleton";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage } from "../elements/Message/MessageText";
import { isPermissionStatus, permissions } from "../utils/centerStatus";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";
import { countryLabel } from "../utils/searchLabel";

const CenterView = () => {
  const { adminId } = useParams();
  const { t } = useTranslation(["translation"]);
  const location = useLocation();

  /**권한 처리 */
  const user = useRecoilValue(userAuth);
  const centerAuth = isPermissionStatus(user?.roleNames, "centerList");

  /**상세 정보 조회 */
  const { data, isLoading } = useGetCenterInfo(adminId);
  const centerData = data?.data;

  /**상세 정보 수정 */
  const centerEditMutation = useEditCenterInfo();
  /**선테 계정 삭제 */
  const centeDeleteMutation = useDeleteCenterInfo();

  const formSchema = Yup.object({
    loginPw: Yup.string()
      .test("hasValue", t(IK.password_min_validate), (value) => !value || value.length >= 8)
      .test("hasValue", t(IK.password_max_validate), (value) => !value || value.length <= 16)
      .test("hasValue", t(IK.password_pattern_validate), (value) => !value || /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[^\s]*$/.test(value)),
    reLoginPw: Yup.string().oneOf([Yup.ref("loginPw"), null], t(IK.password_not_match)),
    roleGroupCode: Yup.string().required(t(IK.role_required)),
    countryCode: Yup.string().required(t(IK.country_required)),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(formSchema) });

  /**계정 정보 수정 */
  const onEditCenter = (data) => {
    const filteredObj = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== ""));
    centerEditMutation.mutate({ adminId: adminId, data: filteredObj });
  };

  /**계정 정보 삭제 */
  const onDeleteCenter = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        centeDeleteMutation.mutate(adminId);
      },
      () => {}
    );
  };

  return (
    <S.CardRow>
      <S.CardCol>
        {isLoading ? (
          <CenterSkeleton />
        ) : (
          <S.CardInner>
            <CardTitle title={`${centerData.name} (${adminId})`} txt />
            <form onSubmit={handleSubmit(onEditCenter)} onKeyDown={enterKeyDown}>
              <S2.FormControlsContainer>
                <FormControlsBox required title={t(IK.id)}>
                  {centerData.loginId}
                </FormControlsBox>

                <FormControlsBox required title={t(IK.password)}>
                  <S2.FormControls type="password" {...register("loginPw")} />
                  <ErrorMessage errors={errors} name="loginPw" />
                </FormControlsBox>

                <FormControlsBox required title={t(IK.password_check)}>
                  <S2.FormControls type="password" {...register("reLoginPw")} />
                  <ErrorMessage errors={errors} name="reLoginPw" />
                </FormControlsBox>

                <FormControlsBox required title={t(IK.center_permissions)}>
                  <S2.FormControls {...register("roleGroupCode")} defaultValue={centerData.roleName} as="select" $select>
                    {permissions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {t(option.text)}
                      </option>
                    ))}
                  </S2.FormControls>
                  <ErrorMessage errors={errors} name="roleGroupCode" />
                </FormControlsBox>

                <FormControlsBox required title={t(IK.country)}>
                  <S2.FormControls {...register("countryCode")} defaultValue={centerData.countryCode || ""} as="select" $select>
                    {countryLabel.map((option) => (
                      <option key={option.value} value={option.value}>
                        {t(option.label)}
                      </option>
                    ))}
                  </S2.FormControls>
                  <ErrorMessage errors={errors} name="countryCode" />
                </FormControlsBox>
              </S2.FormControlsContainer>

              <CardTail line>
                <S3.ButtonLtBox>
                  <S3.StyledButton to={`/centerList${location?.state?.herf || "?page=1"}`}>{t(IK.list)}</S3.StyledButton>
                  {centerAuth.centerDelete && (
                    <S3.StyledButton $secondary type="button" onClick={onDeleteCenter} as="button">
                      {t(IK.delete)}
                    </S3.StyledButton>
                  )}
                  <S3.StyledButton $primary type="submit" as="button">
                    {t(IK.save)}
                  </S3.StyledButton>
                </S3.ButtonLtBox>
              </CardTail>
            </form>
          </S.CardInner>
        )}
      </S.CardCol>
    </S.CardRow>
  );
};

export default CenterView;
