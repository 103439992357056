import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetEventList } from "../apis/eventApi";
import BoardSkeleton from "../components/board/BoardSkeleton";
import BoardTotal from "../components/board/BoardTotal";
import EventCard from "../components/event/EventCard";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import { DatePicker } from "../elements/DatePicker";
import * as S3 from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import * as S2 from "../elements/Table/style/Table.style";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeEventParam, urlParamToObject } from "../utils/searchParams";

const EventBoard = () => {
  const { t } = useTranslation(["translation"]);
  /**검색 조건 설정 */
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const searchs = watch();

  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data, isLoading } = useGetEventList(changeEventParam(searchCondition));
  const boards = data?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    if ((data.startMonth && !data.endMonth) || (!data.startMonth && data.endMonth)) {
      return Notiflix.Report.info("", t(IK.search_entire_date), t(IK.confirm), () => {});
    }
    setSearchCondition((prev) => ({ ...prev, page: 1, ...data }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    const selectElement = document.getElementById("dateControl");
    selectElement.selectedIndex = 0;
    reset({
      searchSelect: "title",
      endEvent: "",
      searchText: "",
      searchStart: "",
      searchEnd: "",
    });
    setSearchCondition({ page: 1 });
  };

  /** 기간 검색*/
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    const [gap, gapDate] = dateValue.split(",");
    if (gap) {
      const startDate = new Date(dayjs().subtract(gap, gapDate).format("YYYY-MM-DD"));
      const endDate = new Date();
      setValue("searchStart", dayjs(startDate).format("YYYY-MM-DD"));
      setValue("searchEnd", dayjs(endDate).format("YYYY-MM-DD"));
    } else {
      setValue("searchStart", "");
      setValue("searchEnd", "");
    }
  };

  /**치료 시작 날짜 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("searchStart", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("searchStart", "");
    }
  };

  /**치료 만료일 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("searchEnd", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setValue("searchEnd", "");
    }
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <S2.TableType2>
              <colgroup>
                <col width="200px"></col>
                <col width="*"></col>
                <col width="200px"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    {t(IK.board_title)}/{t(IK.board_content)}
                  </th>
                  <td>
                    <S3.FormControlsFlex $rowCenter>
                      <S3.FormControls $select as="select" defaultValue={searchCondition?.searchSelect} {...register("searchSelect")}>
                        <option value="title">{t(IK.board_title)}</option>
                        <option value="content">{t(IK.board_content)}</option>
                      </S3.FormControls>
                      <S3.FormControls $regular type="text" placeholder={t(IK.search_text)} defaultValue={searchCondition?.searchText} {...register("searchText")} />
                    </S3.FormControlsFlex>
                  </td>
                  <th scope="row">{t(IK.create_date)}</th>
                  <td>
                    <S3.FormControlsFlex $rowCenter>
                      <S3.FormControls as="select" $select onChange={handleDateChange} id="dateControl">
                        <option value="">{t(IK.entire_date)}</option>
                        <option value={[1, "day"]}>{t(IK.day1)}</option>
                        <option value={[7, "day"]}>{t(IK.day7)}</option>
                        <option value={[1, "month"]}>{t(IK.month1)}</option>
                        <option value={[6, "month"]}>{t(IK.month6)}</option>
                        <option value={[12, "month"]}>{t(IK.year1)}</option>
                      </S3.FormControls>

                      <DatePicker
                        placeholderText={t(IK.date_example)}
                        selected={(searchs.searchStart && new Date(searchs.searchStart)) || (searchCondition?.searchStart && new Date(searchCondition?.searchStart))}
                        handleDataChange={handleStartDate}
                        formControls
                      />

                      <DatePicker
                        placeholderText={t(IK.date_example)}
                        selected={(searchs.searchEnd && new Date(searchs.searchEnd)) || (searchCondition?.searchEnd && new Date(searchCondition?.searchEnd))}
                        handleDataChange={handleEndDate}
                        formControls
                      />
                    </S3.FormControlsFlex>
                  </td>
                </tr>
                <tr>
                  <th scope="row">{t(IK.event_is_progress_title)}</th>
                  <td colSpan={3}>
                    <S3.FormControls {...register("endEvent")} defaultValue={searchCondition?.endEvent} as="select" $select>
                      <option value="">{t(IK.event_is_progress)}</option>
                      <option value="N">{t(IK.event_is_progress_y)}</option>
                      <option value="Y">{t(IK.event_is_progress_n)}</option>
                    </S3.FormControls>
                  </td>
                </tr>
              </tbody>
            </S2.TableType2>
            <S4.ButtonCtBox>
              <S4.StyledButton type="button" onClick={() => onReset()} as="button">
                <i className="ri-refresh-line"></i> {t(IK.reset)}
              </S4.StyledButton>
              <S4.StyledButton $primary type="submit" as="button">
                <i className="ri-search-2-line"></i> {t(IK.search)}
              </S4.StyledButton>
            </S4.ButtonCtBox>
          </form>
        </S.CardInner>
      </S.CardCol>

      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              <CardHead line>
                <BoardTotal total={boards?.totalElements} />
                <S4.StyledButton $primary to="/support/event/post">
                  {t(IK.registration)}
                </S4.StyledButton>
              </CardHead>
              <EventCard contents={boards?.content} dataLength={boards?.totalElements} />
              <CardTail line>
                <Paging totalCount={boards?.totalElements} currentPage={searchCondition.page} size={boards?.size} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default EventBoard;
