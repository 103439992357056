import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BoardPostBox from "../components/board/BoardPostBox";
import { IK } from "../utils/i18n/keyStore";

const NoticePost = () => {
  const { t } = useTranslation(["translation"]);
  const { boardId } = useParams();

  return (
    <div>
      <BoardPostBox boardTitle={t(IK.notice_board)} boardSortCode="NOTICE" boardId={boardId} navi="/support/notice" attachFile />
    </div>
  );
};

export default NoticePost;
