import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { EmptyCard } from "../../elements/EmptyComponent/EmptyCard";
import * as S from "../board/style/BoardList.style";

const EventCard = ({ contents, dataLength }) => {
  return (
    <>
      {dataLength ? (
        <S.BoardCardList>
          {contents.map((content, index) => (
            <li key={index}>
              <Link
                to={`/support/event/post/${content.eventBoardId}`}
                state={{ herf: window.location.search }}
              >
                <dl>
                  <dt>
                    <img src={content.thumb} alt="" />
                  </dt>
                  <dd>
                    <h3>{content.title}</h3>
                    <span>
                      {dayjs(content.startDate).format("YYYY.MM.DD")} -{" "}
                      {dayjs(content.endDate).format("YYYY.MM.DD")}
                    </span>
                  </dd>
                </dl>
              </Link>
            </li>
          ))}
        </S.BoardCardList>
      ) : (
        <EmptyCard />
      )}
    </>
  );
};

export default EventCard;
