import { Link } from "react-router-dom";
import { css, styled } from "styled-components";
import ProfileHeadBg from "../../../../assets/images/patients/profile/profile_head.jpg";

export const CardRow = styled.div`
  width: ${(props) => (props.$wd1440 ? "1440px" : "auto")};
  margin: ${(props) => (props.$wd1440 ? "-10px auto -10px auto" : "-10px")};
`;

export const CardCol = styled.div`
  padding: 10px;
`;

export const CardInner = styled.div`
  padding: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const CardFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const CardColWd = styled.div`
  ${(props) =>
    props.$wd450 &&
    css`
      flex: 0 0 450px;
    `};

  ${(props) =>
    props.$full &&
    css`
      flex: 1;
      overflow: hidden;
    `};
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  h2 {
    font-size: 22px;
  }
  p {
    display: flex;
    gap: 5px;
    span {
      color: ${(props) => props.theme.colors.red600};
    }
  }
`;

export const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: ${(props) => (props.$line ? "20px" : "0")};
  margin-bottom: ${(props) => (props.$line ? "20px" : "15px")};
  border-bottom: 1px solid ${(props) => (props.$line ? props.theme.colors.gray200 : "transparent")};
`;

export const CardTail = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => (props.$line ? "20px" : "0")};
  margin-top: ${(props) => (props.$line ? "20px" : "15px")};
  border-top: 1px solid ${(props) => (props.$line ? props.theme.colors.gray200 : "transparent")};
`;

export const CardProfileWrapper = styled.div`
  position: sticky;
  top: 20px;
  width: 430px;
`;

export const CardProfileBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
`;

export const CardProfileHead = styled.div`
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: url(${ProfileHeadBg}) no-repeat center/cover;
`;

export const CardProfileHeadBtnTop = styled.ul`
  display: flex;
  justify-content: flex-end;
  li:not(:last-child) {
    position: relative;
    margin-right: 10px;
    padding-right: 10px;
    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: -1px;
      width: 1px;
      height: 12px;
      background-color: ${(props) => props.theme.colors.gray600};
    }
  }
  li button {
    display: flex;
    gap: 5px;
    font-weight: 500;
  }
`;

export const CardProfileHeadInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const CardProfileHeadInfoImg = styled.div`
  position: relative;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 50%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const CardProfileHeadInfoTxt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  h2 {
    line-height: 1.3;
    font-size: 24px;
  }
  p {
    font-weight: 500;
  }
`;

export const CardProfileHeadBtnBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 7px;
  button,
  a {
    padding: 6px 11px;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 5px;
    font-weight: 500;
    &:hover {
      background-color: ${(props) => props.theme.colors.blue400};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const CardProfileBodyInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  dl {
    display: flex;
    align-items: baseline;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    font-size: 15px;
  }
  dt {
    flex: 0 0 120px;
    color: ${(props) => props.theme.colors.gray600};
  }
  dd {
    flex: 1;
    padding-left: 15px;
    span {
      font-weight: 500;
    }
  }
`;

// 정보 수정 버튼
export const ProfileEditBtn = styled(Link)`
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  box-shadow: 0px 0 40px rgb(76 87 125 / 2%);
  &:hover {
    background-color: ${(props) => props.theme.colors.blue400};
    border-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ProfileEditBox = styled.dt`
  display: flex;
  align-items: baseline;
  gap: 8px;
  ${ProfileEditBtn} {
    flex: 0 0 30px;
  }
`;
