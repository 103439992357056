import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BoardPostBox from "../components/board/BoardPostBox";
import { IK } from "../utils/i18n/keyStore";

const DoBetterPost = () => {
  const { t } = useTranslation(["translation"]);
  const { boardId } = useParams();

  return (
    <div>
      <BoardPostBox boardTitle={t(IK.do_better)} boardSortCode="ETC" boardId={boardId} navi="/education/do-better" video />
    </div>
  );
};

export default DoBetterPost;
