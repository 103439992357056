import { BrowserRouter, Route, Routes } from "react-router-dom";
import EventBoardPost from "../components/event/EventBoardPost";
import Layout from "../components/layout/Layout";
import BillList from "../pages/BillList";
import BillView from "../pages/BillView";
import CenterAdd from "../pages/CenterAdd";
import CenterList from "../pages/CenterList";
import CenterView from "../pages/CenterView";
import CliniCalGallery from "../pages/CliniCalGallery";
import CliniCalGalleryInfo from "../pages/CliniCalGalleryInfo";
import CouponAdd from "../pages/CouponAdd.jsx";
import CouponList from "../pages/CouponList";
import DeliveryKey from "../pages/DeliveryKey";
import DoBetter from "../pages/DoBetter";
import DoBetterPost from "../pages/DoBetterPost";
import DoctorList from "../pages/DoctorList";
import DoctorView from "../pages/DoctorView";
import EBook from "../pages/EBook";
import EBookPost from "../pages/EBookPost";
import EventBoard from "../pages/EventBoard";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Notice from "../pages/Notice.jsx";
import NoticePost from "../pages/NoticePost.jsx";
import PatientList from "../pages/PatientList";
import PatientTransfer from "../pages/PatientTransfer.jsx";
import PatientView from "../pages/PatientView";
import PopUpList from "../pages/PopUpList.jsx";
import PopUpPost from "../pages/PopUpPost.jsx";
import Resources from "../pages/Resources";
import ResourcesPost from "../pages/ResourcesPost";
import SeraviewFile from "../pages/SeraviewFile";
import StudyList from "../pages/StudyList";
import StudyView from "../pages/StudyView";
import i18n from "../utils/i18n/index.js";

const Router = () => {
  /* i18n 실행 확인용 지우지말 것 */
  i18n.getDataByLanguage("ko");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<Layout />}>
          {/* study */}
          <Route path="/studyList" element={<StudyList />} />
          <Route path="/studyList/:studyId" element={<StudyView />} />
          {/* patient */}
          <Route path="/patientList" element={<PatientList />} />
          <Route path="/patientList/:patientId" element={<PatientView />} />
          {/* doctor */}
          <Route path="/doctorList" element={<DoctorList />} />
          <Route path="/doctorList/:doctorId" element={<DoctorView />} />
          {/* center */}
          <Route path="/centerList" element={<CenterList />} />
          <Route path="/centerList/:adminId" element={<CenterView />} />
          <Route path="/centerList/add" element={<CenterAdd />} />
          {/* coupon */}
          <Route path="/couponList" element={<CouponList />} />
          <Route path="/couponList/add" element={<CouponAdd />} />
          {/* billing */}
          <Route path="/billList" element={<BillList />} />
          <Route path="/billList/:billingId" element={<BillView />} />
          {/* education */}
          <Route path="/education" element={<DoBetter />} />
          <Route path="/education/do-better" element={<DoBetter />} />
          <Route path="/education/do-better/post/:boardId?" element={<DoBetterPost />} />
          <Route path="/education/eBook" element={<EBook />} />
          <Route path="/education/eBook/post/:boardId?" element={<EBookPost />} />
          <Route path="/education/clinical-gallery" element={<CliniCalGallery />} />
          <Route path="/education/clinical-gallery/post/:galleryId?" element={<CliniCalGalleryInfo />} />
          <Route path="/education/resources" element={<Resources />} />
          <Route path="/education/resources/post/:boardId?" element={<ResourcesPost />} />
          {/* support */}
          <Route path="/support" element={<SeraviewFile />} />
          <Route path="/support/seraviewFile" element={<SeraviewFile />} />
          <Route path="/support/deliverykey" element={<DeliveryKey />} />
          <Route path="/support/event" element={<EventBoard />} />
          <Route path="/support/event/post/:eventBoardId?" element={<EventBoardPost />} />
          <Route path="/support/notice" element={<Notice />} />
          <Route path="/support/notice/post/:boardId?" element={<NoticePost />} />
          <Route path="/support/popup" element={<PopUpList />} />
          <Route path="/support/popup/post/:popupId?" element={<PopUpPost />} />
          {/* 환자 이관 */}
          <Route path="/support/patient-transfer" element={<PatientTransfer />} />
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
