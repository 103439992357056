import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetBoardList } from "../apis/boardApi";
import BoardSkeleton from "../components/board/BoardSkeleton";
import BoardTotal from "../components/board/BoardTotal";
import DoBetterCard from "../components/board/DoBetterCard";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import * as S3 from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import * as S2 from "../elements/Table/style/Table.style";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeBoradParam, urlParamToObject } from "../utils/searchParams";

const DoBetter = () => {
  const { t } = useTranslation(["translation"]);
  /**검색 조건 설정 */
  const { register, handleSubmit, reset } = useForm();
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1, sort: "ETC" });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data, isLoading } = useGetBoardList(changeBoradParam(searchCondition));
  const boards = data?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /** 검색버튼 누를시 검색어 제출 */
  const onSubmitSearch = (data) => {
    setSearchCondition((prev) => ({ sort: "ETC", page: 1, ...data }));
  };

  /** 검색어 초기화*/
  const onReset = () => {
    reset({
      boardSelect: "title",
      boardText: "",
    });
    setSearchCondition({ page: 1, sort: "ETC" });
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <S2.TableType2>
              <colgroup>
                <col width="200px"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    {t(IK.board_title)}/{t(IK.board_content)}
                  </th>
                  <td>
                    <S3.FormControlsFlex $rowCenter>
                      <S3.FormControls $select as="select" {...register("boardSelect")} defaultValue={searchCondition?.boardSelect}>
                        <option value="title">{t(IK.board_title)}</option>
                        <option value="content">{t(IK.board_content)}</option>
                      </S3.FormControls>
                      <S3.FormControls type="text" placeholder={t(IK.search_text)} defaultValue={searchCondition?.boardText} {...register("boardText")} />
                    </S3.FormControlsFlex>
                  </td>
                </tr>
              </tbody>
            </S2.TableType2>
            <S4.ButtonCtBox>
              <S4.StyledButton type="button" onClick={() => onReset()} as="button">
                <i className="ri-refresh-line"></i> {t(IK.reset)}
              </S4.StyledButton>
              <S4.StyledButton $primary type="submit" as="button">
                <i className="ri-search-2-line"></i> {t(IK.search)}
              </S4.StyledButton>
            </S4.ButtonCtBox>
          </form>
        </S.CardInner>
      </S.CardCol>

      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              <CardHead line>
                <BoardTotal total={boards?.totalElements} />
                <S4.StyledButton $primary to="/education/do-better/post">
                  {t(IK.registration)}
                </S4.StyledButton>
              </CardHead>

              <DoBetterCard contents={boards?.content} dataLength={boards?.totalElements} />

              <CardTail line>
                <Paging totalCount={boards?.totalElements} currentPage={searchCondition.page} size={boards?.size} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default DoBetter;
