import EmptyImage from "../../assets/images/common/symbol.png";
import * as S from "./style/EmptyCard.style";

export const EmptyCard = ({ text }) => {
  return (
    <S.EmptyBox>
      <div>
        <img src={EmptyImage} alt="empty" />
      </div>
    </S.EmptyBox>
  );
};
