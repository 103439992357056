import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGalleryList } from "../apis/boardApi";
import BoardTotal from "../components/board/BoardTotal";
import ClinicalGalleryTr from "../components/clinicalGallery/ClinicalGalleryTr";
import ClinicalSearchFilter from "../components/clinicalGallery/ClinicalSearchFilter";
import MemoModal from "../components/clinicalGallery/MemoModal";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeParam, urlParamToObject } from "../utils/searchParams";
const CliniCalGallery = () => {
  const { t } = useTranslation(["translation"]);

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });

  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  const { data, isLoading } = useGetGalleryList(changeParam(searchCondition));
  const galleries = data?.data;
  const tableCol = ["160px", "160px", "160px", "160px", "160px", "160px", "160px", "160px"];
  const tableHead = [t(IK.clinical_image), t(IK.clinical_is_submit), t(IK.clinic_name), t(IK.patient_name), t(IK.treatment_option), t(IK.register_date), t(IK.coupon_btn), t(IK.memo)];

  /**메모 모달*/
  const [memoModal, setMemoModal] = useState({ isOpen: false, text: "" });

  return (
    <S.CardRow>
      <S.CardCol>
        <ClinicalSearchFilter setSearchCondition={setSearchCondition} searchCondition={searchCondition} />
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={galleries?.totalElements} />
              <S4.StyledButton $primary to="/education/clinical-gallery/post">
                {t(IK.registration)}
              </S4.StyledButton>
            </CardHead>
            <DataTable tableCol={tableCol} dataLength={galleries?.totalElements} tableHead={tableHead} tableTr={<ClinicalGalleryTr setMemoModal={setMemoModal} galleries={galleries?.content} />} />
            <MemoModal memoModal={memoModal} setMemoModal={setMemoModal} />
            <CardTail>
              <Paging totalCount={galleries?.totalElements} currentPage={searchCondition.page} size={galleries?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default CliniCalGallery;
