import { useTranslation } from "react-i18next";
import * as S from "../../components/board/style/BoardList.style";
import * as S2 from "../../styles/Common";
import { isPermissionStatus } from "../../utils/centerStatus";
import { IK } from "../../utils/i18n/keyStore";
import { pageListNumber } from "../../utils/pageListNumber";
import { seraphinOption, studyStatusListLabel } from "../../utils/studyStatus";

const StudyTableTr = ({ studys, page, totalElements, roleNames }) => {
  const { t } = useTranslation(["translation"]);

  /**악궁 표기 */
  const ArchList = {
    1: t(IK.bimaxillay),
    2: t(IK.maxilla),
    3: t(IK.mandible),
  };

  /**권한처리 */
  const studyAuth = isPermissionStatus(roleNames, "studyList");

  return (
    <>
      {studys.map((study, index) => (
        <tr key={study.studyId}>
          <td>{pageListNumber(totalElements, page, index)}</td>
          <td>
            {studyAuth.naviDoctor ? (
              <S.BoardTableLink to={`/doctorList/${study.doctorId}`} state={{ herf: window.location.search, category: "study" }}>
                {study.doctorName}
                {study?.doctorKoreaName && `(${study.doctorKoreaName})`}
                <S.BoardTableGrade $grade={study?.doctorGrade} />
                <br />
                {study.shipAddressName}
              </S.BoardTableLink>
            ) : (
              <>
                {study.doctorName} {study?.doctorKoreaName && `(${study.doctorKoreaName})`}
                <S.BoardTableGrade $grade={study?.doctorGrade} />
                <br />
                {study.shipAddressName}
              </>
            )}
          </td>
          <td>
            <S.BoardTableLink to={`/studyList/${study.studyId}`} state={{ herf: window.location.search }}>
              {`${study.patientFirstName} ${study.patientLastName}`} {study.patientKrName && `(${study?.patientKrName})`}
              <br />
              {study.patientNumber}
            </S.BoardTableLink>
          </td>
          <td>
            {seraphinOption[study.packages]} <br />
            {study?.SI01 && `(${ArchList[study?.SI01]})`}
          </td>
          <td>
            {(study.detail === "01" || study.detail > 10) && (
              <S.BoardTableState>
                <S.BoardTableStateList>
                  <S.BoardTableStateItem $progress={study.detail <= "14"} $complete={study.detail >= "14"}>
                    <span></span>
                  </S.BoardTableStateItem>
                  {!(study.packages === "RP" || study.replacementYn === "Y" || study?.additionalProcess === "none") && (
                    <S.BoardTableStateItem $progress={study.detail <= "17" && study.detail > "14"} $complete={study.detail >= "17"}>
                      <span></span>
                    </S.BoardTableStateItem>
                  )}
                  <S.BoardTableStateItem $last $progress={study.detail >= "18" && study.detail !== "21"} $complete={study.detail === "20" || study.detail === "22"}>
                    <span></span>
                  </S.BoardTableStateItem>
                </S.BoardTableStateList>
                <h4>
                  {study?.additionalAmountYn === "Y" && `${t(IK.pay_need)}) `}
                  {study.additionalYn === "Y" && `${t(IK.additional_device)} `}
                  {study.replacementYn === "Y" && `${t(IK.alternative_correction_devices)} `}
                  {studyStatusListLabel(study, t)}
                </h4>
                <p>
                  {study?.lastCommentByDoctor === "Y" && <S2.TextDiv $textColorRed600>{t(IK.comment_writed_doctor)}</S2.TextDiv>}
                  {study?.lastCommentByDoctor === "N" && <S2.TextDiv>{t(IK.comment_writed_center)}</S2.TextDiv>}
                </p>
              </S.BoardTableState>
            )}
          </td>
          <td>{study.startDate}</td>
          <td>{study.endDate}</td>
          {!roleNames?.includes("DENTIS_ADMIN") && <td>{study.managerName}</td>}
          {!roleNames?.includes("DENTIS_ADMIN") && <td>{study.advisoryName}</td>}
          <td>{study.SI15 === "1" ? "O" : "X"}</td>
          <td>{study.reOrderYn === "Y" ? "O" : "X"}</td>
        </tr>
      ))}
    </>
  );
};

export default StudyTableTr;
