import axios from "axios";
import Notiflix from "notiflix";
import { IK } from "../utils/i18n/keyStore";

/**파일 다운로드 */
export const downloadFile = async (fileId, fileName, t) => {
  Notiflix.Loading.standard("");
  try {
    const response = await axios.get(`/api/file/${fileId}`, {
      responseType: "blob",
    });

    const fileBlob = new Blob([response.data], { type: "application/octet-stream" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = fileName;
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove();
  }
};

/**파일 이미지 경로 */
export const getFileImageUrl = async (fileId) => {
  const response = await axios.get(`/api/file/${fileId}`, {
    responseType: "blob",
  });

  const fileBlob = new Blob([response.data], { type: "application/octet-stream" });
  const fileURL = URL.createObjectURL(fileBlob);

  return fileURL;
};
