import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getLoadPatientExcel, useGetPatientList } from "../apis/patientApi";
import BoardTotal from "../components/board/BoardTotal";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import PatientFilter from "../components/patients/PatientFilter";
import { PatientsTalbleTr } from "../components/patients/PatientsTalbleTr";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { useSearch } from "../hooks/useSearch";
import { IK } from "../utils/i18n/keyStore";
import { changeParam, urlParamToObject } from "../utils/searchParams";

const PatientList = () => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data: patientData, isLoading } = useGetPatientList(changeParam(searchCondition));
  const patients = patientData?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /**엑셀 다운로드 */
  const handleExcel = () => {
    const langInfo = localStorage.getItem("langInfo");
    getLoadPatientExcel(changeParam({ ...searchCondition, page: 1 }), t, langInfo);
  };

  const tableHead = [
    t(IK.patient_name),
    t(IK.birthday),
    t(IK.gender),
    t(IK.doctor_name),
    t(IK.search_clinic),
    t(IK.treatment_option),
    t(IK.status),
    `${t(IK.patient)} ${t(IK.register_date)}`,
    t(IK.agreement),
  ];
  const tableCol = ["330px", "130px", "130px", "160px", "160px", "130px", "*", "130px", "130px"];

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <PatientFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} />
        </S.CardInner>
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <CardHead>
              <BoardTotal total={patients?.totalElements} />
              <S2.ButtonFlex>
                {searchCondition.doctorId && (
                  <S2.StyledButton $primary type="submit" onClick={() => navigate(`/doctorList/${searchCondition.doctorId}`)} as="button">
                    {t(IK.back_doctor_profile)}
                  </S2.StyledButton>
                )}
                <S2.StyledButton $excel type="submit" onClick={handleExcel} as="button">
                  <i className="ri-file-excel-2-line"></i> {t(IK.download_patient_list)}
                </S2.StyledButton>
              </S2.ButtonFlex>
            </CardHead>
            <DataTable BoardTableType2 tableCol={tableCol} tableHead={tableHead} dataLength={patients?.content.length} tableTr={<PatientsTalbleTr patients={patients?.content} />} />
            <CardTail>
              <Paging totalCount={patients?.totalElements} currentPage={searchCondition.page} size={patients?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default PatientList;
