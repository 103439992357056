import { useTranslation } from "react-i18next";
import { IK } from "../../../utils/i18n/keyStore";
import { studyTypeLabel } from "../../../utils/studyStatus";
import * as S from "../style/Study.style";

const ApprovedSeraview = ({ studyInfo }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <S.StudyHistoryCircle />
      <S.StudyHistoryItem>
        <S.StudyHistoryDepth1>
          <h3>
            [{t(IK[studyTypeLabel(studyInfo)])}] {t(IK.approved_seraview)}
          </h3>
          <span>
            {studyInfo?.createdDate} {studyInfo?.modifiedBy && studyInfo.writerShow && `(${studyInfo.modifiedBy})`}
          </span>
        </S.StudyHistoryDepth1>
      </S.StudyHistoryItem>
    </>
  );
};

export default ApprovedSeraview;
