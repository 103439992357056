import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S2 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "./style/Footer.style";

const Footer = () => {
  const { t, i18n } = useTranslation(["translation"]);

  /**언어 설정을 변경한 유저의 값 가져오기 */
  const [langInfo, setLangInfo] = useState(localStorage.getItem("langInfo"));

  /**언어 설정 변경 */
  const onChangeLang = () => {
    const newLangInfo = langInfo === "ko" ? "en" : "ko";
    setLangInfo(newLangInfo);
    localStorage.setItem("langInfo", newLangInfo);
  };

  useEffect(() => {
    i18n.changeLanguage(langInfo);
  }, [langInfo, i18n]);

  return (
    <S.FooterBox>
      <S.Copyright>{t(IK.tns_copyright)}</S.Copyright>
      <S.FooterInfo>
        <li>
          <S.LanguageBtn type="button" onClick={onChangeLang}>
            {t(IK.language)}
            <S2.CountBadge>{langInfo?.toUpperCase() || i18n?.language?.toLowerCase()}</S2.CountBadge>
          </S.LanguageBtn>
        </li>
      </S.FooterInfo>
    </S.FooterBox>
  );
};

export default Footer;
